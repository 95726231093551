<template>
    <v-app id="header">
        <header id="nav-header">
            <section id="navigation">
                    <div class="navbar navbar-default navbar-static-top"
                        role="navigation">
                        <div class="flex justify justify-content-between ml-8 mr-8">
                            <div class="container-nav">
                                <!-- <img class="logo" :src="estabelecimento.logo || require('@assets/logo-white.png')"
                                    :alt="`Logo ${estabelecimento.nome}`"> -->
                                <span class="logo" />
                                <h2 class="title d-none d-sm-none d-md-inline-block">{{ estabelecimento.nome }}</h2>

                                <div>
                                    <v-icon class="icon-header d-inline-block d-sm-inline-block d-md-inline-block d-lg-none"
                                        size="35" @click="$emit('miniCalendarOpen')">far fa-calendar-alt</v-icon>
                                    <v-badge :class="{'filter-icon-container': true, 'active': filterActive}" :color="(filterActive) ? 'orange' : ''" dot overlap>
                                        <v-icon class="icon-header" size="40" @click="$emit('filterOpen')">filter_list</v-icon>
                                    </v-badge>
                                    <v-badge class="bell-icon-container" :color="(countNotifications) ? 'orange' : ''"
                                        :content="countNotifications" overlap>
                                        <v-icon :class="{'icon-header': true, 'bell': notificationFlag}" size="40"
                                        @click="$emit('reservasOpen')">language</v-icon>
                                    </v-badge>
                                    <!-- <v-icon :class="{'icon-header d-none d-sm-inline-flex': true, 'rotate': updating}" size="40"
                                        @click="requestRefresh">{{ updating ? 'autorenew' : 'refresh' }}</v-icon> -->
                                    <v-icon class="icon-header menu-bar-icon" size="48" @click="$emit('menuOpen')">menu</v-icon>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
        </header>
    </v-app>
</template>

<script>
export default {
    name: 'HeaderMenu',
    components: {},
    props: {
        estabelecimento: {
            type: Object,
            default: function () {
                return new Object()
            },
        },
        countNotifications: {
            type: Number,
            default: 0,
        },
        filterActive: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        updating: false,
        notificationFlag: true,
        menuMobile: false,
        lastCountNotifications: null,
        sound: new Audio('/referee-whistle.mp3'),
    }),
    watch: {
        countNotifications: function() {
            if (this.lastCountNotifications === null) { // Pula quando não inicializado
                this.lastCountNotifications = this.countNotifications
            } else if (this.lastCountNotifications < this.countNotifications){
                this.lastCountNotifications = this.countNotifications
                this.animateBell()
            }
        },
    },
    mounted() { },
    methods: {
        animateBell: function() {
            this.notificationFlag = true
            this.sound.play()
            setTimeout(() => (this.notificationFlag = false), 2500)
        },
        requestRefresh: function() {
            this.$emit('refreshHorarios')

            this.updating = true
            setTimeout(() => (this.updating = false), 2500)
        },
    }
}
</script>

<style lang="sass" scoped>
    @import '@assets/css/global-vars.sass'


    #header ::v-deep
        #nav-header
            display: block
            width: 100%
            margin-bottom: 13px
            background: $blue

            *
                color: $white

            h2.title
                font-family: Open Sans, Roboto !important
                font-size: 2.2em !important
                font-weight: 600

            .icon-header
                margin: 0 10px
                transition: all 1s ease-in-out

                &:first-child
                    margin-left: 0
                &:last-child
                    margin-right: 0

            .filter-icon-container.active .v-badge--dot .v-badge__badge
                top: 3px

            .v-badge
                margin: 0 10px
                .icon-header
                    margin: 0
            .v-badge.bell-icon-container
                .v-badge__badge orange
                    left: calc(100% - 17px)
                    top: 3px
                    border-radius: 50%
                    height: 12px
                    width: 12px

            .rotate
                animation: rotateAnimation 1.5s ease-in-out infinite

            .bell
                animation: bellAnimation .2s ease-in-out 5

            .logo
                height: 100px
                width: auto

            .container-nav
                display: flex
                width: 100%
                height: auto
                justify-content: space-between
                align-items: center

            #navigation,
            #navigation > div
                padding: 0


            @media (max-width: 500px)
                #navigation > .navbar > div
                    margin: 0 10px 0 7px !important

                .icon-header,
                .v-badge
                    margin: 0 7px
                    font-size: 35px !important
                    vertical-align: middle

                .logo
                    height: 80px

            @media (max-width: 360px)
                .logo
                    display: none

                #navigation .container-nav
                    margin: 12px 0 8px

                    > div
                        width: 100%
                        text-align: right

        @media (max-width: 500px)
            #nav-header
                margin-bottom: 0


    @keyframes rotateAnimation
        from
            transform: rotate(0deg)
        to
            transform: rotate(360deg)

    @keyframes bellAnimation
        0%
            transform: rotate(0deg)
        25%
            transform: rotate(45deg)
        50%
            transform: rotate(0deg)
        100%
            transform: rotate(-45deg)
</style>