<template>
    <v-app id="mensagens-table">
        <v-dialog id="modal-mensagens" v-model="openViewModel"
        content-class="modal-form" fullscreen
        hide-overlay scrollable eager
        transition="dialog-bottom-transition">

            <v-card>
                <v-toolbar flat dark color="matchpal" max-height="56px">
                    <v-icon class="mr-4" size="32" @click="openViewModel = false">close</v-icon>
                    <v-toolbar-title>Mensagens</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-4">
                    <v-select
                    v-model="msg_type"
                    :items="items"
                    item-text="titulo"
                    item-value="tipo"
                    label="Selecione uma mensagem"
                    :disabled="editMode"
                    @change="msg_text = getMsg(msg_type)"
                    />
                    <v-textarea
                        ref="msg_textarea"
                        v-model="msg_text"
                        outlined
                        auto-grow
                        label="Texto da mensagem"
                        :disabled="!editMode"
                        />

                    <v-row v-if="!editMode && !!msg_type" justify="end" class="ma-1">
                        <v-btn dark @click="editMode=true">Editar</v-btn>
                    </v-row>


                    <v-row v-if="editMode" justify="center" class="ma-1 pb-4">
                        <v-btn class="my-1 mx-2" elevation="2" small @click="insertField('#estabelecimento_nome ')"
                        >Estabelecimento</v-btn>

                        <v-btn class="my-1 mx-2" elevation="2" small @click="insertField('#URL ')"
                        >Link do estabelecimento</v-btn>

                        <v-btn class="my-1 mx-2" elevation="2" small @click="insertField('#cliente_nome ')"
                        >Cliente</v-btn>

                        <v-btn class="my-1 mx-2" elevation="2" small @click="insertField('#quadra_nome ')"
                        >Quadra</v-btn>

                        <v-btn class="my-1 mx-2" elevation="2" small @click="insertField('#data ')"
                        >Data</v-btn>

                        <v-btn class="my-1 mx-2" elevation="2" small @click="insertField('#dia_semana')"
                        >Dia da semana</v-btn>

                        <v-btn class="my-1 mx-2" elevation="2" small @click="insertField('#hora ')"
                        >Hora</v-btn>

                        <v-btn class="my-1 mx-2" elevation="2" small @click="insertField('#duracao ')"
                        >Duração</v-btn>

                        <v-btn class="my-1 mx-2" elevation="2" small @click="insertField('#preco ')"
                        >Preço</v-btn>

                        <v-btn class="my-1 mx-2" elevation="2" small @click="insertField('#observacao ')"
                        >Observação</v-btn>

                        <v-btn class="my-1 mx-2" elevation="2" small @click="insertField('#tipo_marcacao ')"
                        >Tipo de marcação</v-btn>

                        <v-btn v-if="['web_recusada','web_pix_pendente','web_pix_vencido','web_confirmada'].includes(msg_type)"
                        class="my-1 mx-2" elevation="2" small @click="insertField('#reserva_web_URL ')"
                        >Link da reserva web</v-btn>
                    </v-row>

                    <v-row v-if="editMode" justify="end" class="ma-1">
                        <v-btn dark @click="msg_text = getDefaultMsg(msg_type)">Carregar padrão</v-btn>
                    </v-row>

                    <v-row v-if="editMode" justify="end" class="ma-1">
                        <v-btn color="orange" class="ma-1" @click="editMode=false; msg_text = getMsg(msg_type)">Cancelar</v-btn>
                        <v-btn dark class="ma-1 " @click="setMsg(msg_type, msg_text)">Salvar</v-btn>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
        <Load :load="loading" />
    </v-app>
</template>

<script>
import Utils from "@components/Utils/Utils.vue"
//import detectMobileBrowser from '@plugins/detectmobilebrowser.js'


export default {
    name: 'Mensagens',
    components: {
        Load: () => import('@components/Load.vue'),
    },
    mixins: [ Utils ],
    props: {
        mensagensFlag: {
            type: Number,
            default: 0,
        },
        estabelecimento: {
            type: Object,
            default: function () {
                return new Object()
            }
        },
    },
    data: () => ({
        openViewModel: false,
        loading: false,
        //isMobile: detectMobileBrowser(),
        editMode: false,
        items: [
            { tipo: 'lembrete', titulo: 'Lembrete' },
            { tipo: 'app_marcacao', titulo: 'Notificação após marcação pelo app' },
            { tipo: 'avulso_desmarcado', titulo: 'Notificação após desmarcação de avulso' },
            { tipo: 'recorrente_desmarcado', titulo: 'Notificação após desmarcação de fixo ou mensal' },
            { tipo: 'recorrente_liberado', titulo: 'Notificação após liberar essa semana fixo ou mensal' },
            { tipo: 'web_confirmada', titulo: 'Reserva web - confirmada' },
            { tipo: 'web_recusada', titulo: 'Reserva web - solicitação recusada' },
            { tipo: 'web_pix_pendente', titulo: 'Reserva web - pix pendente' },
            { tipo: 'web_pix_vencido', titulo: 'Reserva web  - pix vencido' },
            //{ tipo: 'convite', titulo: 'Convite' },
            //{ tipo: 'compartilhar', titulo: 'Compartilhar Horário' },
        ],
        msg_type: '',
        msg_text: '',
    }),
    computed: {
    },
    watch: {
        mensagensFlag: function() {
            this.openViewModel = true
        },
    },
    mounted() {
    },
    destroyed() {
    },

    methods: {
        insertField: function(fieldLabel){
            // get cursor's position:
            let ta = this.$refs.msg_textarea.$refs.input,
                startPos = ta.selectionStart,
                endPos = ta.selectionEnd,
                tmpStr = ta.value

            // insert:
            this.msg_text = tmpStr.substring(0, startPos) + fieldLabel + tmpStr.substring(endPos, tmpStr.length)

        },
        setMsg: function(type, text){
            this.loading = true
            this.$http({
                method: 'post',
                url: '/apiweb/setAviso',
                data: {
                    estabelecimento_id: this.estabelecimento.id,
                    tipo: type,
                    texto: text,
                }
            }).then(response => {
                if (response.data.success == true) {
                    // Avisa a Agenda para atualizar o estabelecimento
                    this.$emit('refreshMsg')
                }
                this.editMode = false
                this.loading = false
            })
        },
        getMsg: function(type){
            let custom_msg = this.estabelecimento.whatsapp_msg.find(obj => { return obj.tipo === type })
            if(!custom_msg){
                return this.getDefaultMsg(type)
            }else{
                return custom_msg.texto
            }

        },
        getWhatsMsg: function(type, reservaInfo){
            let msg_text = this.getMsg(type)

            msg_text = msg_text.replaceAll("#estabelecimento_nome", this.estabelecimento.nome)
            msg_text = msg_text.replaceAll("#URL", (!!this.estabelecimento.id_url) ? `https://zumer.com.br/${ this.estabelecimento.id_url }` : '')
            msg_text = msg_text.replaceAll("#cliente_nome", reservaInfo.nome)
            msg_text = msg_text.replaceAll("#quadra_nome", reservaInfo.quadra)
            msg_text = msg_text.replaceAll("#data", reservaInfo.data)
            msg_text = msg_text.replaceAll("#dia_semana", reservaInfo.dia_semana)
            msg_text = msg_text.replaceAll("#hora", reservaInfo.hora)
            msg_text = msg_text.replaceAll("#duracao", reservaInfo.duracao)
            msg_text = msg_text.replaceAll("#preco", reservaInfo.preco)
            msg_text = msg_text.replaceAll("#observacao", (!!reservaInfo.obs) ? reservaInfo.obs : '')
            msg_text = msg_text.replaceAll("#tipo_marcacao", reservaInfo.tipo)
            msg_text = msg_text.replaceAll("#reserva_web_URL", (!!reservaInfo.codigo_web) ? `https://zumer.com.br/reserva/${ reservaInfo.codigo_web }` : '')

            return encodeURIComponent(msg_text)
        },
        getDefaultMsg: function(type){
            let msg_text = ''
            switch (type) {
            case 'app_marcacao':
                msg_text = 'Seu horário foi marcado, #cliente_nome!\n\n'
                            +'Dados do seu horário #tipo_marcacao:\n'
                            +'⏰ *#data às #hora*\n'
                            +'duração de #duracao\n'
                            +'#quadra_nome\n'
                            +'R$ #preco\n'
                            +'OBS: #observacao\n\n'

                if(this.estabelecimento.id_url)
                    msg_text = msg_text + `Para visualizar a agenda completa, acesse o link\n#URL\n\n`

                msg_text = msg_text + `Obrigado pela preferência!\n#estabelecimento_nome`
                break
            case 'lembrete':
                msg_text = 'Lembre de seu horário, #cliente_nome!\n\n'
                            +'Você tem uma reserva em:\n'
                            +'⏰ *#data às #hora*\n'
                            +'duração de #duracao\n'
                            +'#quadra_nome\n'
                            +'R$ #preco\n'
                            +'OBS: #observacao\n\n'

                if(this.estabelecimento.id_url)
                    msg_text = msg_text + `Para visualizar a agenda completa, acesse o link\n#URL\n\n`

                msg_text = msg_text + `Obrigado pela preferência!\n#estabelecimento_nome`
                break
            case 'web_recusada':
                msg_text = 'Olá, #cliente_nome!\n\n'
                            +'❌ Marcação *RECUSADA*.\n\n'
                            +'Dados do horário solicitado:\n'
                            +'⏰ *#data às #hora*\n'
                            +'duração de #duracao\n'
                            +'#quadra_nome\n'
                            +'R$ #preco\n'
                            +'OBS: #observacao\n\n'
                            +'Gostaria de marcar em outro horário?\n\n'

                if(this.estabelecimento.id_url)
                    msg_text = msg_text + `Para visualizar a agenda completa, acesse o link\n#URL\n\n`

                msg_text = msg_text + 'Obrigado pela preferência!\n#estabelecimento_nome'
                break
            case 'web_pix_pendente':
                msg_text = 'Olá, #cliente_nome!\n\n'
                            +'⏳ Sua reserva está *PENDENTE*.\n\n'
                            +'O prazo para pagamento do pix é de 20 minutos após a criação. Após esse limite a reserva será cancelada.\n\n'
                            +'Para realizar o pagamento, acesse sua reserva em #reserva_web_URL.\n\n'
                            +'Dados do horário solicitado:\n'
                            +'⏰ *#data às #hora*\n'
                            +'duração de #duracao\n'
                            +'#quadra_nome\n'
                            +'R$ #preco\n'
                            +'OBS: #observacao\n\n'
                            +'Obrigado pela preferência!\n'
                            +'#estabelecimento_nome\n'
                            +'#URL'
                break
            case 'web_pix_vencido':
                msg_text = 'Olá, #cliente_nome!\n\n'
                            +'❌ Sua reserva foi *CANCELADA*.\n\n'
                            +'O prazo para pagamento do pix é de 20 minutos após a criação, após esse prazo a solicitação de reserva é automaticamente cancelada.\n\n'
                            +'Dados do horário cancelado:\n'
                            +'⏰ *#data às #hora*\n'
                            +'duração de #duracao\n'
                            +'#quadra_nome\n'
                            +'R$ #preco\n'
                            +'OBS: #observacao\n\n'
                            +'Obrigado pela preferência!\n'
                            +'#estabelecimento_nome\n'
                            +'#URL'
                break
            case 'web_confirmada':
                msg_text = 'Olá, #cliente_nome!\n\n'
                            +'✅ Sua solicitação de marcação foi confirmada.\n\n'
                            +'Dados do horário solicitado:\n'
                            +'⏰ *#data às #hora*\n'
                            +'duração de #duracao\n'
                            +'#quadra_nome\n'
                            +'R$ #preco\n'
                            +'OBS: #observacao\n\n'
                            +'Obrigado pela preferência!\n'
                            +'#estabelecimento_nome\n'
                            +'#URL'
                break
            case 'avulso_desmarcado':
                msg_text = 'Olá, #cliente_nome!\n\n'
                            +'❌ Sua reserva foi *CANCELADA*.\n\n'
                            +'Dados do horário cancelado:\n'
                            +'⏰ *#data às #hora*\n'
                            +'duração de #duracao\n'
                            +'#quadra_nome\n'
                            +'R$ #preco\n'
                            +'OBS: #observacao\n\n'
                            +'Obrigado pela preferência!\n'
                            +'#estabelecimento_nome\n'
                            +'#URL'
                break
            case 'recorrente_desmarcado':
                msg_text = 'Olá, #cliente_nome!\n\n'
                            +'❌ Sua reserva foi *CANCELADA*.\n\n'
                            +'Dados do horário #tipo_marcacao cancelado:\n'
                            +'⏰ *#dia_semana às #hora*\n'
                            +'duração de #duracao\n'
                            +'#quadra_nome\n'
                            +'R$ #preco\n'
                            +'OBS: #observacao\n\n'
                            +'*Reserva cancelada a partir de #data, apenas horários anteriores a essa data continuam marcados.*\n\n'
                            +'Obrigado pela preferência!\n'
                            +'#estabelecimento_nome\n'
                            +'#URL'
                break
            case 'recorrente_liberado':
                msg_text = 'Olá, #cliente_nome!\n\n'
                            +'❌ Sua reserva no dia *#data* foi *CANCELADA*.\n\n'
                            +'Nas demais datas está mantido seu horário #tipo_marcacao de #dia_semana às #hora.\n\n'
                            +'Dados do horário cancelado:\n'
                            +'⏰ *#data às #hora*\n'
                            +'duração de #duracao\n'
                            +'#quadra_nome\n'
                            +'R$ #preco\n'
                            +'OBS: #observacao\n\n'
                            +'Obrigado pela preferência!\n'
                            +'#estabelecimento_nome\n'
                            +'#URL'
                break
            case 'convite':
                msg_text = 'Precisamos de ${ kinfOfNeed } para completar a partida. Se tiver '
                + 'interesse por favor entre em contato. ${ this.estabelecimento.nome }, '
                + '${ this.quadraSelecionadaNome }, dia ${ this.formatedDate } às '
                + '${ this.formatedStarHour } com duração de ${ this.durationStr }'
                break
            case 'compartilhar':
                msg_text = 'Confira os horários disponíveis de ${this.estabelecimento.nome}.'
                + ' ${ horariosLivresStr }'
                + '\nPara fazer sua reserva acesse o link: '
                + 'https://zumer.com.br/${this.estabelecimento.id_url}'
                break
            default:
                msg_text = 'Olá!'
            }

            return msg_text
        },
    }
}
</script>

<style lang="sass" scoped>
    @import '@assets/css/global-vars.sass'

</style>