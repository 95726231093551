<template>
    <div>
        <Header
            :estabelecimento="estabelecimento"
            :count-notifications="requestNotification"
            :filter-active="filterActive"
            @refreshHorarios="refreshFlag++"
            @notificationOpen="notificationFlag++"
            @filterOpen="filterFlag++"
            @miniCalendarOpen="miniCalendarFlag++"
            @menuOpen="menuFlag++"
            @reservasOpen="reservasFlag++"
            v-on="$listeners" />

        <Horarios
            :refresh-flag="refreshFlag"
            :filter-flag="filterFlag"
            :add-event-flag="addEventFlag"
            :mini-calendar-flag="miniCalendarFlag"
            :estabelecimento="estabelecimento"
            @filterActive="filterActive = $event" />

        <Menu
            :open-flag="menuFlag"
            :estabelecimento="estabelecimento"
            @refreshHorarios="refreshFlag++"
            @mensagemOpen="mensagensFlag++"
            v-on="$listeners" />

        <Mensagens
            :estabelecimento="estabelecimento"
            :mensagens-flag="mensagensFlag"
            @refreshMsg="fetchEstabelecimento()" />

        <ReservasWeb
            :reservas-flag="reservasFlag"
            :estabelecimento="estabelecimento"
            @refreshHorarios="refreshFlag++"
            @requestNotification="requestNotification = $event" />
    </div>
</template>

<script>
import Header from '@components/Header.vue'
import Horarios from '@components/Horarios.vue'
import Mensagens from '@components/Mensagens.vue'

export default {
    name: 'Agenda',
    components: {
        Header,
        Horarios,
        Mensagens,
        Menu: () => import('@components/Menu.vue'),
        ReservasWeb: () => import('@components/ReservasWeb.vue'),
    },
    data: () => ({
        estabelecimento: {},
        refreshFlag: 0,
        filterFlag: 0,
        mensagensFlag: 0,
        addEventFlag: 0,
        miniCalendarFlag: 0,
        requestNotification: 0,
        menuFlag: 0,
        reservasFlag: 0,
        filterActive: false,
    }),
    mounted() {
        this.fetchEstabelecimento()

        // Expose updateAgenda to iOS container
        window.updateAgenda = () => {
            this.refreshFlag++
        }
    },
    methods: {
        fetchEstabelecimento: function () {
            this.$http.post('/apiweb/getEstabelecimento').then(response => {
                if (response.data.success === true)
                    this.estabelecimento = response.data.info
                else history.go(0)
            })
        },
    }
}
</script>

<style lang="sass">
</style>