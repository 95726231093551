import { render, staticRenderFns } from "./Mensagens.vue?vue&type=template&id=7d232cf4&scoped=true&"
import script from "./Mensagens.vue?vue&type=script&lang=js&"
export * from "./Mensagens.vue?vue&type=script&lang=js&"
import style0 from "./Mensagens.vue?vue&type=style&index=0&id=7d232cf4&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d232cf4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VApp,VBtn,VCard,VCardText,VDialog,VIcon,VRow,VSelect,VTextarea,VToolbar,VToolbarTitle})
