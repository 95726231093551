<template>
    <v-app id="horario">
        <div class="container-fluid">
            <div class="row">
                <v-navigation-drawer v-model="openMiniCalendarDrawer"
                    fixed temporary right touchless width="300">
                    <v-toolbar color="matchpal" style="position: sticky; top: 0; z-index: 10; margin-bottom: 5px;">
                        <v-icon class="mr-4" size="32" @click="openMiniCalendarDrawer = false">close</v-icon>
                        <v-toolbar-title>Calendário Mensal</v-toolbar-title>
                    </v-toolbar>

                    <div id="calendarMobileContainer" class="mt-8" />
                </v-navigation-drawer>

                <div id="calendarDesktopContainer" class="d-none d-lg-block col-lg-2 text-center">
                    <vue-cal id="mini-calendar" xsmall locale="pt-br" :time="false"
                        hide-view-selector active-view="month"
                        class="vuecal--blue-theme vuecal--rounded-theme"
                        :selected-date="selectedDate"
                        :start-week-on-sunday="true"
                        :disable-views="['years', 'year', 'week', 'day']"
                        style="max-width: 270px; height: 290px; margin-bottom: 20px;"
                        @cell-focus="selectedDate = $event"
                        @cell-click="openMiniCalendarDrawer = false" />
                    <v-btn id="addEvent" dark @click="addEvent">Adicionar</v-btn>
                </div>
                <!-- TODO: :min-date="new Date()" Ver como deixar ativado mas com cor diferente nos passados -->

                <div class="col-md-12 col-lg-10">
                    <vue-cal id="vuecal"
                        locale="pt-br"
                        :time-from="vuecalConfig.startDay"
                        :time-to="vuecalConfig.endDay"
                        :time-cell-height="80" :time-step="vuecalConfig.timeStep"
                        :disable-views="['week','years', 'year', 'month']"
                        :split-days="quadrasShowing" :sticky-split-labels="true"
                        :cell-click-hold="false"
                        hide-view-selector active-view="day"
                        :min-cell-width="80" :min-split-width="vuecalConfig.minCellWidth"
                        :selected-date="selectedDate" :class="{'pastDate': isPastDay}"
                        :on-event-click="editEvent" :events="eventsShowing"
                        :on-event-create="addEvent" :drag-to-create-event="false"
                        :editable-events="{ title: false, drag: false, resize: false, delete: false, create: true }"
                        @view-change="fetchHorarios"
                        @ready="inicializeCarroselQuadras"
                        @cell-click="isMobile ? {} : addEvent($event)"
                        @cell-dblclick="isMobile ? addEvent($event) : {}" />
                        <!-- Props para evento drag
                             OBS.: Cuidar a prop snap-to-time que afeta a marcação do horário quando clicado, pois passa a retornar o intervalo clicado baseado nesse valor
                             :drag-to-create-event="true"
                             :snap-to-time="vuecalConfig.timeStep"
                             :drag-to-create-threshold="0" -->
                </div>

                <v-dialog id="modal-horarios" v-model="dialog"
                    content-class="modal-form" fullscreen
                    hide-overlay scrollable eager persistent no-click-animation
                    transition="dialog-bottom-transition">
                    <v-card tile>
                        <v-toolbar flat dark color="matchpal" max-height="70px">
                            <v-btn icon dark @click="dialog = false">
                                <v-icon>close</v-icon>
                            </v-btn>

                            <v-toolbar-title v-if="agendamento.action == 'add'"
                                class="headline pl-1">
                                Marcar horário
                                <v-icon size="25" :style="{'margin-left': '10px', 'vertical-align': 'text-top'}">far fa-calendar-plus</v-icon>
                            </v-toolbar-title>
                            <v-toolbar-title v-else-if="agendamento.vuecalObj.info && agendamento.vuecalObj.info.pix_status == 'pendente'"
                                class="headline pl-1">
                                Aguardando Pix
                            </v-toolbar-title>
                            <v-toolbar-title v-else
                                class="headline pl-1">
                                Editar horário
                                {{ agendamento.tipo }}
                                <!-- <v-icon size="30">edit</v-icon> -->
                            </v-toolbar-title>

                            <v-menu v-if="agendamento.action != 'add'"
                                bottom left offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-icon class="ml-5" tag="a" size="35px" color="green" v-on="on">fab fa-whatsapp</v-icon>
                                </template>
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <WhatsLink v-if="agendamento.telefone"
                                                :phone="telefoneUnformatted">
                                                Conversar
                                            </Whatslink>
                                            <span v-else style="color: red; cursor: pointer"
                                            @click="notify('Você não informou nenhum número de telefone.')">
                                                Conversar
                                            </span>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="agendamento.vuecalObj.info && agendamento.vuecalObj.info.pix_status != 'pendente'">
                                        <v-list-item-title>
                                            <WhatsLink v-if="agendamento.telefone"
                                                :phone="telefoneUnformatted"
                                                :text="whatsReminderText">
                                                Enviar lembrete
                                            </Whatslink>
                                            <span v-else style="color: red; cursor: pointer"
                                            @click="notify('Você não informou nenhum número de telefone.')">
                                                Enviar lembrete
                                            </span>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="agendamento.vuecalObj.info && agendamento.vuecalObj.info.pix_status != 'pendente'">
                                        <v-list-item-title>
                                            <a href @click.prevent="dialogInviteOptions = true">Enviar convite</a>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <div v-else />
                        </v-toolbar>
                        <v-card-text>
                            <v-form ref="form" class="mt-5" lazy-validation
                                @submit.prevent="sumitFormModalEvent">
                                <v-row>
                                    <v-col class="icon-btn-on-input" cols="12" sm="4" md="4">
                                        <v-text-field :id="`${prefix}-name`"
                                            v-model="agendamento.nome" label="Nome *"
                                            type="text" name="name" :autocomplete="(contactsAPISuport||!isMobile)?'off':'name'"
                                            required />
                                        <v-icon v-if="contactsAPISuport"
                                            class="icon-btn-on-input-right"
                                            @click="abreContatos">far fa-address-book</v-icon>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-text-field
                                            :id="`${prefix}-phone`"
                                            v-model="agendamento.telefone"
                                            v-maska="'(##) # ####-####'"
                                            label="Telefone"
                                            type="tel" name="phone" :autocomplete="(contactsAPISuport||!isMobile)?'off':'tel'"
                                            v-on:blur="agendamento.telefone=maskPhone(agendamento.telefone.replace(/\D/g, ''))" />
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-text-field v-model="agendamento.cpf"
                                            v-maska="'###.###.###-##'" label="CPF"
                                            type="tel" name="cpf" autocomplete="off" />
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-menu ref="menu" v-model="miniCalendar" :close-on-content-click="false"
                                            transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="formatedDate" label="Data da reserva *"
                                                    prepend-icon="event" readonly :disabled="agendamento.action!='add' || selecionadoAgenda"
                                                    required v-on="on" />
                                            </template>
                                            <v-date-picker v-model="selectedDate"
                                                locale="pt-BR" scrollable
                                                @input="miniCalendar = false" />
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-select v-model="agendamento.quadraMarcada"
                                            name="quadraMarcada" :items="quadrasItems"
                                            label="Quadra *" required
                                            :disabled="agendamento.action!='add' || selecionadoAgenda"
                                            @change="changeQuadra, duracao = []" />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-select v-model="agendamento.esporte" name="esporte"
                                            :items="esportesQuadra" label="Esporte *"
                                            no-data-text="Nenhuma quadra selecionada"
                                            :disabled="agendamento.action!='add'" required />
                                    </v-col>
                                    <v-col cols="12" sm="2">
                                        <v-select id="horaSelect" v-model="horaMarcada"
                                            name="horariosMarcados" :items="horarios" required
                                            :label="(agendamento.action=='add') ? 'Horário de início *' : `Início às ${ formatedStarHour }`"
                                            :no-data-text="(agendamento.quadraMarcada)?'Nenhum horário livre para essa quadra':'Nenhuma quadra selecionada'"
                                            :disabled="agendamento.action!='add'"
                                            @change="pegaIntervalo" />
                                    </v-col>
                                    <v-col cols="12" sm="2">
                                        <v-select id="duracaoSelect" v-model="fimDoEvento"
                                            name="duracao" :items="duracao" required
                                            :label="(agendamento.action=='add') ? 'Duração *' : `Duração ${ durationStr }`"
                                            :no-data-text="'Nenhum Horário selecionado'"
                                            :disabled="agendamento.action!='add'"
                                            @change="onChangeDuracao" />
                                    </v-col>
                                    <v-col cols="12" sm="2">
                                        <v-text-field id="preco" v-model="agendamento.preco"
                                            v-maska="['#,##', '##,##', '###,##', '####,##']"
                                            class="money-prefix" name="preco"
                                            type="tel" label="Preço *"
                                            autocomplete="off" required
                                            :disabled="agendamento.vuecalObj.info && agendamento.vuecalObj.info.pix_status != null">
                                            <template v-slot:prepend>
                                                <v-icon v-if="agendamento.vuecalObj.info && agendamento.vuecalObj.info.pix_status != null"
                                                :class="(agendamento.vuecalObj.info.pix_status == 'pago') ? 'pix-icon-paid' : 'pix-icon-pending'">
                                                    fa-brands fa-pix
                                                </v-icon>
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col v-if=" agendamento.tipo == 'mensal' && agendamento.action != 'add' " cols="12">
                                        <v-text-field id="mensal"
                                            v-model="agendamento.valorPago"
                                            v-maska="['#,##', '##,##', '###,##', '####,##']" class="money-prefix"
                                            name="mensal" :label="`Valor recebido em ${periodoString}`"
                                            autocomplete="off" />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field id="observacao"
                                            v-model="agendamento.observacao"
                                            name="observacao" label="Observações"
                                            autocomplete="off" />
                                    </v-col>
                                </v-row>
                                <v-spacer />
                                <v-card-actions>
                                    <template v-if="agendamento.action == 'add'">
                                        <v-row>
                                        <v-col col="12">
                                            <WhatsLink v-if="estabelecimento.bloqueio != null"
                                                        :text="`O estabelecimento ${estabelecimento.nome} está bloqueado. Gostaria de obter informações para regularizar a situação.`"
                                                        phone="5555996794845">
                                                <v-alert class="mb-5" border="left" dense outlined text type="error">
                                                    Estabelecimento bloqueado. Entre em contato com o suporte clicando aqui.
                                                </v-alert>
                                            </WhatsLink>
                                            <div class="row d-flex justify-center justify-sm-end">
                                                <v-btn type="submit" class="white--text ev-color-avulso clustered" :disabled="estabelecimento.bloqueio != null" @click="agendamento.tipo = 'avulso'">Avulso</v-btn>
                                                <v-btn type="submit" class="white--text ev-color-fixo clustered" :disabled="estabelecimento.bloqueio != null" @click="agendamento.tipo = 'fixo'">Fixo</v-btn>
                                                <v-btn type="submit" class="white--text ev-color-mensal clustered" :disabled="estabelecimento.bloqueio != null" @click="agendamento.tipo = 'mensal'">Mensal</v-btn>
                                            </div>
                                        </v-col>
                                        </v-row>
                                    </template>
                                    <template v-else>
                                        <div class="row d-flex justify-space-between">
                                            <div>
                                                <v-btn v-if="agendamento.tipo != 'avulso' && !isPastDay"
                                                    type="button" color="warning" dark @click="desmarcarSemana">
                                                    Liberar essa semana
                                                </v-btn>
                                                <v-btn class="mr-4" type="button" color="warning" dark @click="desmarcar">Desmarcar</v-btn>
                                            </div>
                                            <v-btn type="submit" dark class="ev-color-avulso">Salvar</v-btn>
                                        </div>
                                    </template>
                                </v-card-actions>
                            </v-form>
                        </v-card-text>

                        <div style="flex: 1 1 auto;" />
                    </v-card>
                </v-dialog>

                <v-dialog id="modal-alert" v-model="dialogEditOptions" content-class="modal-form" persistent
                    scrollable max-width="600px">
                    <v-card loading="false">
                        <v-card-title class="headline grey lighten-2" primary-title>
                            <v-icon size="25" :style="{'margin-right': '10px', 'vertical-align': 'text-top', 'color': '#000'}">fa-exclamation-triangle</v-icon>
                            Aviso
                        </v-card-title>
                        <v-card-text class="text-center mt-4 subtitle-1">
                            Este horário é {{ agendamento.tipo }}!
                            Você deseja alterar somente para o dia {{ formatedDate }} ou para todos os dias?
                            <v-card-actions class="pt-4 pb-0 d-flex justify-center flex-wrap">
                                <v-btn type="reset"
                                    color="error" dark @click="dialogEditOptions = false">Cancelar
                                </v-btn>

                                <v-btn type="button" dark class="ev-color-fixo" @click="optionEdit(true)">Todos os dias</v-btn>
                                <v-btn type="button" dark class="ev-color-avulso" @click="optionEdit(false)">Somente o dia {{ formatedDate }}</v-btn>
                            </v-card-actions>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-dialog id="modal-invite" v-model="dialogInviteOptions"
                    max-width="550px" scrollable>
                    <v-card loading="false">
                        <v-card-title class="headline grey lighten-2" primary-title>
                            <v-icon size="25" :style="{'margin-right': '10px', 'vertical-align': 'text-top', 'color': '#000'}">fa-share</v-icon>
                            Enviar convite
                        </v-card-title>
                        <v-card-text class="mt-4">
                            <v-container fluid>
                                <p class="subtitle-1">Preciso de:</p>
                                <v-radio-group v-model="typeInvite" :mandatory="false">
                                    <v-radio label="Time adversário" value="team" />
                                    <v-radio label="Jogador(es)" value="player" />
                                </v-radio-group>
                                <v-text-field v-if="typeInvite == 'player'" id="preco"
                                    v-model="numberPlayers2Invite"
                                    type="tel" name="numberPlayers2Invite"
                                    label="Número de jogadores faltantes *" required />
                            </v-container>
                            <v-card-actions class="pt-4 pb-0 d-flex justify-space-between">
                                <v-btn type="reset"
                                    color="error" dark @click="dialogInviteOptions = false">Cancelar
                                </v-btn>

                                <WhatsLink icon="after"
                                    class="v-btn v-btn--flat v-btn--text theme--light v-size--default indigo--text matchpal"
                                    :text="whatsInviteText">
                                    Enviar convite
                                </WhatsLink>
                            </v-card-actions>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <Snackbar :snackbar-flag="snackbar.activeFlag" :permanent="snackbar.permanentFlag"
                    :error="snackbar.errorFlag" :color="snackbar.color" :hide-close-buttom="snackbar.isConfirmMode">
                    <template v-slot:message>{{ snackbar.text }}</template>
                    <template v-slot:actions>
                        <WhatsLink v-if="snackbar.phone && !snackbar.isConfirmMode" class="v-btn v-btn--flat v-btn--text theme--light v-size--default indigo--text"
                            :phone="snackbar.phone" :text="snackbar.whatsappMsg" icon="after">
                            Avisar
                        </WhatsLink>
                        <v-btn v-if="snackbar.isConfirmMode" color="error"
                            @click.prevent="snackbar.confirm = false; snackbar.activeFlag = 0">Não</v-btn>
                        <v-btn v-if="snackbar.isConfirmMode" color="matchpal"
                            @click.prevent="snackbar.confirm = true; snackbar.activeFlag = 0">Sim</v-btn>
                    </template>
                </Snackbar>

                <Filters :filter-flag="filterFlag" :quadras="quadras"
                    @change="applyFilter" />

                <Load :load="load" />
            </div>

            <button id="arrow-splitdays-left" :class="{
                'vuecal__arrow vuecal__arrow--prev arrow-splitdays': true,
                'd-none': (carroselSplitDays.numberOfPages <= 1 || carroselSplitDays.pageNow == 0)
                }">
                <i class="angle" />
            </button>
            <button id="arrow-splitdays-right" :class="{
                'vuecal__arrow vuecal__arrow--next arrow-splitdays': true,
                'd-none': (carroselSplitDays.numberOfPages <= 1 || carroselSplitDays.pageNow == carroselSplitDays.numberOfPages-1)
                }">
                <i class="angle" />
            </button>
        </div>
    </v-app>
</template>

<script>
import moment from 'moment'
import 'moment/locale/pt'
import { maska } from 'maska'
import vueCal from 'vue-cal'
import vueCalPtBr from 'vue-cal/dist/i18n/pt-br.js'
import 'vue-cal/dist/vuecal.css'
import delay from 'lodash/delay'
import kebabCase from 'lodash/kebabCase'
import debounce from 'lodash/debounce'
import groupBy from 'lodash/groupBy'
import detectMobileBrowser from '@plugins/detectmobilebrowser.js'
import Utils from '@components/Utils/Utils.vue'
import Requests from '@components/Utils/Requests.vue'
import Mensagens from '@components/Mensagens.vue'

export default {
    name: 'Horarios',
    components: {
        vueCal,
        Filters: () => import('@components/Filters.vue'),
        Snackbar: () => import('@components/Snackbar.vue'),
        WhatsLink: () => import('@components/WhatsappLink.vue'),
        Load: () => import('@components/Load.vue'),
    },
    directives: { maska },
    mixins: [ Utils, Requests, Mensagens ],
    props: {
        refreshFlag: {
            type: Number,
            default: 0
        },
        filterFlag: {
            type: Number,
            default: 0
        },
        addEventFlag: {
            type: Number,
            default: 0
        },
        teste: {
            type: Number,
            default: 0
        },
        miniCalendarFlag: {
            type: Number,
            default: 0
        },
        estabelecimento: {
            type: Object,
            default: function () {
                return new Object()
            },
        },
    },
    data: () => ({
        dialog: false,
        dialogEditOptions: false,
        dialogInviteOptions: false,
        dialogFullscreen: false,
        openMiniCalendarDrawer: false,
        textoMensagem: '',
        tipoMensagem: '',
        typeInvite: 'team',
        numberPlayers2Invite: 1,
        miniCalendar: false,
        todosDias: false,
        selectedDate: moment(new Date()).format('YYYY-MM-DD'),
        quadras: [],
        duracao:  [],
        horaMarcada: '',
        horariosLivres: [],
        horariosFechado: [],
        events: [],
        fimDoEvento: '',
        quadrasDisplayed: [],
        quadrasDisplayedByFilter: [],
        load: false,
        horariosOrdenados: [],
        selecionadoAgenda: false,
        vuecalConfig: {
            startDay: 0 * 60,
            endDay: 24 * 60,
            timeStep: 60,
            minCellWidth: 250,
        },
        agendamento: {
            action: '',
            nome: '',
            tipo: '',
            preco: 0,
            esporte: 0,
            telefone: '',
            horariosMarcados: [],
            cpf: '',
            quadraMarcada: 0,
            observacao: '',
            valorPago: 0,
            vuecalObj: {info:
                {isHorarioFixo: 0}
            },
        },
        eventBackgroundRefresh: {},
        carroselSplitDays: {
            pageLength: 0,
            numberOfPages: 0,
            pages: [],
            pageNow: 0,
        },
        oldSizeOfScreen: 0,
        prefix: 0,
        isMobile: detectMobileBrowser(),

        featuresFlags: {
            calcRange: false,
        }
    }),
    computed: {
        quadrasItems: function() {
            return this.quadras.map(q => ({text: q.nome, value: q.id}))
        },
        quadrasShowing: function () {
            if (!this.quadrasDisplayed.length) return []
            return this.quadras.filter(q => this.quadrasDisplayed.includes(q.id)).map(q => ({
                label: q.nome,
                class: kebabCase(q.nome)
            }))
        },
        quadraSelecionadaNome: function () {
            if (!this.agendamento.quadraMarcada) return ''
            return this.quadras.filter(q => this.agendamento.quadraMarcada == q.id)[0].nome
        },
        eventsShowing: function() {
            return this.events.filter(ev => this.quadrasDisplayed.includes(ev.info.quadra_id))
        },
        horarios: function() {
            if (!this.agendamento.quadraMarcada) return []
            return this.horariosLivres[this.agendamento.quadraMarcada]
                .map(h => ({text: moment(h.hora_inicio, 'HH:mm:ss').format('HH:mm'), value: h.id}))
        },
        esportesQuadra: function() {
            if (!this.agendamento.quadraMarcada) return []
            let esportesQuadra = this.quadras.filter(q => this.agendamento.quadraMarcada == q.id)[0].esportes
            return esportesQuadra.map(q => ({text: q.tipo, value: q.id}))
        },
        formatedDate: {
            get: function() {
                return moment(this.selectedDate).format('DD/MM/YYYY')
            },
            set: function(date) {
                // this.selectedDate = moment(date).format('YYYY-MM-DD')
                this.$store.commit('selectedDate', moment(date).format('YYYY-MM-DD'))
            },
        },
        periodoString: {
            get: function() {
                return moment(this.selectedDate).format('MMMM')
            },
            set: function() {
                this.periodoString = moment(this.selectedDate).format('MMMM')
            },
        },
        horarioAdjacente: function() {
            if (this.agendamento.action == "add"){
                if(this.agendamento.horariosMarcados.length == 1){
                    return true
                }
                let array = []
                array = this.orderHorarios()
                for (let i = 0; i < this.agendamento.horariosMarcados.length - 1; i++) {
                    const endTime = moment(array[i].hora_inicio, 'HH:mm:ss').add(array[i].duracao, 'minutes').format('HH:mm:ss')
                    if (endTime != array[i+1].hora_inicio){
                        this.notify('Os horários precisam ser adjacentes!', true)
                        return false
                    }
                }
            }
            return true
        },
        durationStr: function() {
            let start = moment(this.agendamento.vuecalObj.start, 'YYYY-MM-DD HH:mm:ss')
            let end = moment(this.agendamento.vuecalObj.end, 'YYYY-MM-DD HH:mm:ss')
            let duration = moment.duration(end.diff(start))
            let minutes = duration.asMinutes()

            return this.stringDuracao(minutes)

            /*let durationString = (hours) ? `${hours} h` : ''
            durationString += (hours && minutes) ? ' e ' : ''
            durationString += (minutes) ? `${minutes} m` : ''
            return durationString*/
        },
        formatedStarHour: function() {
            return moment(this.agendamento.vuecalObj.start, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
        },
        msgLink: function(){
            let msg = '%0AObrigado pela preferência!'

            if(this.estabelecimento.id_url)
                msg = `Para visualizar a agenda completa, acesse o link%0A`
                    + `https://zumer.com.br/${ this.estabelecimento.id_url }`
                    + msg

            return msg
        },
        whatsConfirmationText: function() {
            const reservaInfo = {
                nome: this.agendamento.nome,
                quadra: this.quadraSelecionadaNome,
                data: this.formatedDate,
                dia_semana: moment(this.selectedDate).format('dddd'),
                hora: this.horarioWhats, //this.formatedStarHour
                duracao: this.duracaoWhats, //this.durationStr
                preco: this.agendamento.preco,
                obs: this.agendamento.observacao,
                tipo: this.agendamento.tipo,
                codigo_web: '',
            }
            return this.getWhatsMsg('app_marcacao', reservaInfo)
        },
        whatsReminderText: function() {
            const reservaInfo = {
                nome: this.agendamento.nome,
                quadra: this.quadraSelecionadaNome,
                data: this.formatedDate,
                dia_semana: moment(this.selectedDate).format('dddd'),
                hora: this.formatedStarHour,
                duracao: this.durationStr,
                preco: this.agendamento.preco,
                obs: this.agendamento.observacao,
                tipo: this.agendamento.tipo,
                codigo_web: '',
            }
            return this.getWhatsMsg('lembrete', reservaInfo)
        },
        whatsInviteText: function() {
            let kinfOfNeed = ''
            if(this.typeInvite == 'player') {
                kinfOfNeed = `${ this.numberPlayers2Invite } Jogador`
                kinfOfNeed += (this.numberPlayers2Invite > 1) ? 'es' : ''
            } else
                kinfOfNeed = 'Time adversário'
            return `Precisamos de ${ kinfOfNeed } para completar a partida. Se tiver `
                + `interesse por favor entre em contato. ${ this.estabelecimento.nome }, `
                + `${ this.quadraSelecionadaNome }, dia ${ this.formatedDate } às `
                + `${ this.formatedStarHour } com duração de ${ this.durationStr }`
        },
        telefoneUnformatted: function() {
            if (!this.agendamento.telefone) return ''
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            const onlyDigits = this.agendamento.telefone.replace(/\D/g, '')
            return '55' + onlyDigits
        },
        isPastDay: function() {
            return moment(this.selectedDate).isBefore(
                moment(new Date).format('YYYY-MM-DD') // remove horas
            )
        },
    },
    watch: {
        refreshFlag: debounce(function() {
            this.fetchHorarios()
        }, 1000, {
            'leading': true,
            'trailing': false
        }),
        addEventFlag: function() {
            this.addEvent()
        },
        miniCalendarFlag: function() {
            this.openMiniCalendarDrawer = true
        },
        quadrasDisplayedByFilter: function() {
            this.$emit('filterActive', !!this.quadrasDisplayedByFilter.length)
        },
        flagContactSelected: function() {
            this.agendamento.nome = this.contactInfo.name
            this.agendamento.telefone = this.contactInfo.tel
        },
    },
    mounted() {
        let scrl = document.getElementsByClassName('vuecal__bg')
        let time = moment().format('HH')
        let offset = document.getElementsByClassName('vuecal__time-cell')
        scrl[1].scrollTo(0,time * offset[0].clientHeight)

        moment.defaultFormat = 'DD-MM-YYYY HH:mm'

        setTimeout( () => this.fetchHorarios(), 800)

        this.eventBackgroundRefresh = setInterval(this.fetchHorarios, 2 * 60 * 1000, false, false) // 2 minutos
    },
    created: function () {
        navigator.serviceWorker.addEventListener('message', this.serviceWorkerListener)

        //ADICIONA HISTORICO PRA EVITAR FECHAMENTO DO APP TESTE

        window.addEventListener('load', function(e){
            history.pushState(null, document.title, location.href)
        })
        window.addEventListener('popstate', this.historyManager)
        window.addEventListener('popstate', this.fecharModal)
    },
    destroyed() {
        clearInterval(this.eventBackgroundRefresh)

        window.removeEventListener('popstate', this.historyManager, false)
        window.removeEventListener('popstate', this.fecharModal, false)

    },
    methods: {
        maskPhone (phone){
            if (phone) {
                let i = 0
                let pattern = '(##) ####-####'
                if (phone.length > 10)
                    pattern = '(##) # ####-####'

                const masked = pattern.replace(/#/g, () => {
                    return (phone[i]) ? phone[i++] : ""
                })
                return masked
            }else{
                return ''
            }
        },
        getSelectedDay(){
            let x = document.getElementsByClassName("vuecal__title")
            x = x[1].innerText
            x = x.substr(x.indexOf(" ") + 1)
            x = moment(x, "DD MMMM YYYY").format('YYYY-MM-DD')
            sessionStorage.setItem('dataSelct', x)
        },
        fecharModal(){
            this.dialog = false
            this.openMiniCalendarDrawer = false
        },
        historyManager(){
            history.pushState(null, document.title, location.href)
        },
        serviceWorkerListener: function (event){
            this.fetchHorarios()
        },
        fetchHorarios: function (date, loadShow = true) {
            if (!!date) {
                if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
                    this.selectedDate = date
                } else if (!!date.startDate && moment.isDate(date.startDate)) {
                    this.selectedDate = moment(date.startDate).format('YYYY-MM-DD')
                } else {
                    this.notify('Erro ao atualizar agenda. Por favor entre em contato com nosso suporte!')
                    this.load = false
                    return
                }
            }

            if(loadShow) this.load = true

            this.$http({
                method: 'post',
                url: '/apiweb/agenda',
                data: {
                    data: this.selectedDate
                }
            }).then(response => {
                this.events = response.data.reservas.map(ev => {
                    ev.telefone = (ev.telefone) ? ev.telefone.replace(/\D/g, '').slice(2) : ''
                    let labelConfig = this.getLabelEvent(ev)
                    return {
                        'title': labelConfig.innerHTML,
                        'start': ev.start,
                        'end': ev.end,
                        'class': `ev-color-${ev.tipo} ${labelConfig.customClass}`,
                        'split': null,
                        'contentFull': ev.observacoes,
                        'content': '',
                        'info': ev
                    }
                })
                this.horariosLivres = response.data.horariosLivres
                this.quadras = response.data.quadras
                if (!this.quadrasDisplayed.length) this.setNumberOfSplits()
                this.bloquearHorarios(response.data.horariosBloqueados)
                this.bloquearHorarios(response.data.horariosFechado, '')
                this.horariosFechado = response.data.horariosFechado
                this.setEventSplit() // Atualiza o split do evento conforme posição do carrosel
                if(loadShow) this.load = false
                this.getSelectedDay()
            }).catch(error => {
                if(loadShow) this.load = false
                history.go(0)
            })
        },
        addEvent(payloadEvent) {
            this.prefix = Math.trunc(Math.random() * 100000000)
            this.dialog = true
            this.tipoMensagem = 'adicionado'
            this.selecionadoAgenda = false
            this.agendamento = {
                action: 'add',
                nome: '',
                tipo: 'avulso',
                preco: '0,00',
                esporte: 0,
                telefone: '',
                horariosMarcados: [],
                cpf: '',
                quadraMarcada: 0,
                observacao: '',
                vuecalObj: {},
            }
            //this.onChangeHorarios()

            if (!!payloadEvent) {
                // Marcar quadra de acordo com o horário clicado
                this.agendamento.quadraMarcada = this.quadrasDisplayed[payloadEvent.split-1]
                this.agendamento.esporte = this.esportesQuadra[0].value

                // Selecionar o esporte quando existir somente 1 para a quadra
                //if(this.esportesQuadra.length === 1)

                // Marcar o horário conforme o que foi clicado
                if (!!payloadEvent.date) {
                    this.selecionadoAgenda = true
                    let horarioIdSelecionado = this.getHorarioLivre(
                        payloadEvent.date,
                        this.agendamento.quadraMarcada
                    )
                    // Se existir o horário clicado, marcar
                    if (!!horarioIdSelecionado){
                        this.horaMarcada = horarioIdSelecionado
                        this.pegaIntervalo()
                        this.fimDoEvento = this.duracao[0].value
                        this.onChangeDuracao()
                    }
                }
            }
        },
        editEvent(agendamentoVuecal) {
            if (agendamentoVuecal.class === 'blocked hour-lock' || agendamentoVuecal.class === 'blocked hour-lock tiny')
                this.eventClickEventLocked(agendamentoVuecal)

            if (agendamentoVuecal.editable === false) return

            this.prefix = Math.trunc(Math.random() * 100000000)
            this.dialog = true
            this.tipoMensagem = "editado"
            // Popula formulário
            this.agendamento = {
                action: 'edit',
                tipo: agendamentoVuecal.info.tipo,
                quadraMarcada: agendamentoVuecal.info.quadra_id,
                esporte: agendamentoVuecal.info.esporte,
                preco: parseFloat(agendamentoVuecal.info.preco).toFixed(2).replace('.',','),//agendamentoVuecal.info.preco,//).replace('.',','),
                horariosMarcados: agendamentoVuecal.info.horario_id,
                observacao: agendamentoVuecal.info.observacoes,
                nome: agendamentoVuecal.info.nome,
                telefone: this.maskPhone(agendamentoVuecal.info.telefone),
                cpf: agendamentoVuecal.info.cpf,
                vuecalObj: agendamentoVuecal,
                valorPago: parseFloat(agendamentoVuecal.info.valorPago).toFixed(2).replace('.',','),
            }
        },
        onChangeHorarios: function() {
            if (this.agendamento.action == 'add' && this.agendamento.quadraMarcada && this.agendamento.horariosMarcados.length){
                this.agendamento.preco = parseFloat(
                    this.horariosLivres[this.agendamento.quadraMarcada]
                        .filter(q => this.agendamento.horariosMarcados.includes(q.id))
                        .map(h => h.preco)
                        .reduce((a, b) => a + b, null)
                ).toFixed(2).replace('.',',')
            }
            else if (this.agendamento.action != 'add' && this.agendamento.vuecalObj.info){
                this.agendamento.preco = parseFloat(this.agendamento.vuecalObj.info.preco)/*.toFixed(2)*/.replace('.',',')
            }
            else{
                this.agendamento.preco = parseFloat(0).toFixed(2).replace('.',',')
            }
            //this.agendamento.preco = parseFloat(this.agendamento.preco.replace(',','.'))
        },
        pegaIntervalo: function(){
            this.fimDoEvento = ''
            let index = this.horarios.findIndex(hora => hora.value == this.horaMarcada)
            let t
            let intervalos = []
            let temp = null
            for (let i = index, cont = 0; i < this.horariosLivres[this.agendamento.quadraMarcada].length; i++) {
                t = this.horariosLivres[this.agendamento.quadraMarcada][i].duracao
                if (temp == this.horarios[i].text || this.horaMarcada === this.horarios[i].value) {
                    intervalos.push({text: t + cont + ' min', value: this.horarios[i].value})
                    temp = (moment(this.horarios[i].text, 'HH:mm').add(t, 'minutes').format("HH:mm"))
                    cont += t
                }
            }
            this.duracao = intervalos
        },
        onChangeDuracao: function(){
            this.agendamento.horariosMarcados = [this.horaMarcada]
            let index = this.horarios.findIndex(hora => hora.value == this.horaMarcada)
            if (this.horaMarcada === this.fimDoEvento) {
                this.onChangeHorarios()
                return
            }
            for (let i = ++index; i < this.horariosLivres[this.agendamento.quadraMarcada].length; i++) {
                this.agendamento.horariosMarcados.push(this.horarios[i].value)
                if (this.fimDoEvento === this.horarios[i].value) {
                    this.onChangeHorarios()
                    return
                }
            }
        },
        optionEdit(todosDias) {
            if(![0,10,11].includes(this.agendamento.telefone.replace(/\D/g, '').length)){
                return this.notify('Verifique o número de telefone informado.', true)
            }
            this.todosDias = todosDias
            let postConfigObj = {}
            if (this.agendamento.vuecalObj.info.existeAtivo == 1 && !this.todosDias){
                if (this.agendamento.tipo === 'mensal'){
                    postConfigObj = this.buildPostAvulsoMensalEdit()
                }
                else{
                    postConfigObj = this.buildPostAvulsoEdit()
                }
            }
            else postConfigObj = this.buildPostFixoEdit()
            this.submit(postConfigObj)
        },
        sumitFormModalEvent: function (e) {
            // let horarioAtivo = [];
            // for (let i = 0; i < this.agendamento.horariosMarcados.length; i++) {
            //     for (let j = 0; j < this.horariosLivres[this.agendamento.quadraMarcada].length; j++) {
            //         if (this.agendamento.horariosMarcados[i] == this.horariosLivres[this.agendamento.quadraMarcada][j].hora_inicio) {
            //             let horarioId = this.horariosLivres[this.agendamento.quadraMarcada][j].id;
            //             let objHorarioAtivo = `{nome_sem_app: ${this.agendamento.nome}, num_sem_app: ${this.telefoneUnformatted}, `
            //                 +`cpf: ${this.agendamento.cpf}, observacoes: ${this.agendamento.observacao}, esporte_id: 1, `
            //                 +`horario_id: ${horarioId}, usuario_id: 0, data: "2019-12-09 00:00:00"}`;
            //             this.horarioAtivo.push(objHorarioAtivo)
            //         }
            //     }
            // }
            // let a = JSON.stringify(horarioAtivo)
            // let data = '{"horarioAtivo":' + JSON.stringify(a) + ', "versao_horarioAtivo":' + 119813 + '}';
            let postConfigObj = {}

            if (this.agendamento.action == "add"){
                if(!this.horaMarcada) {
                    return this.notify('Selecione o horário de início', true)
                } else if(!this.fimDoEvento){
                    return this.notify('Selecione a duração do evento', true)
                } else if(!this.agendamento.esporte) {
                    return this.notify('Selecione um esporte', true)
                } else if(!this.agendamento.nome) {
                    return this.notify('O nome é obrigatório', true)
                }
                if(![0,10,11].includes(this.agendamento.telefone.replace(/\D/g, '').length)){
                    return this.notify('Verifique o número de telefone informado.', true)
                }
                let hoje = moment(new Date()).format('YYYY-MM-DD')
                if(moment(this.selectedDate).isBefore(hoje)){
                    return this.notify('Você não pode adicionar horários no passado.', true)
                }
                if(moment(this.selectedDate).isSame(hoje)){
                    let index = this.horarios.findIndex(hora => hora.value == this.horaMarcada)
                    if(moment(this.horarios[index].text, 'HH:mm').isBefore())
                        return this.notify('Você não pode adicionar horários no passado.', true)
                }
                let horariosOrdenados = this.orderHorarios()
                this.horarioWhats = moment(horariosOrdenados[0].hora_inicio,'HH:mm').format('HH:mm')
                this.duracaoWhats = 0
                for (let i = 0; i < this.agendamento.horariosMarcados.length; i++) {
                    this.duracaoWhats = horariosOrdenados[i].duracao + this.duracaoWhats
                    this.agendamento.horariosMarcados[i] = horariosOrdenados[i].id
                }
                this.duracaoWhats = this.stringDuracao(this.duracaoWhats)
                if (this.agendamento.tipo === 'avulso') postConfigObj = this.buildPostEventAvulso()
                else if (this.agendamento.tipo === 'fixo') postConfigObj = this.buildPostEventFixo()
                else if (this.agendamento.tipo === 'mensal') postConfigObj = this.buildPostEventMensal()
                // else this.notify('Houve um erro interno no aplicativo! Por favor entre em contato com o administrador.', true)

                // Se fixo ou mensal, verifica conflitos com avulsos no futuro
                if (this.agendamento.tipo === 'fixo' || this.agendamento.tipo === 'mensal'){
                    this.$http({
                        method: 'post',
                        url: '/apiweb/getConflitosFuturos',
                        data: {
                            data: this.selectedDate,
                            horario_ids: this.agendamento.horariosMarcados
                        }
                    }).then(response => {
                        let conflitos = response.data.conflitos
                        if (conflitos.length) {
                            conflitos = conflitos.map(d => moment(d,'YYYY-MM-DD').format('DD/MM/YY'))
                            let confirmMsg = 'Conflito com horário avulso no futuro: ' +
                                            conflitos.join(', ') +
                                            '. Nesta data será mantida a reserva avulsa e liberado o fixo. Deseja continuar?'

                            this.confirm(confirmMsg)
                                .then(confirm => {
                                    if(confirm === true){
                                        this.submit(postConfigObj)
                                    }
                                })

                            this.load = false
                        } else {
                            this.submit(postConfigObj)
                        }
                    }).catch(error => {
                        this.load = false
                        this.notify('Erro ao consultar os conflitos futuros!', true)
                    })
                } else {
                    this.submit(postConfigObj)
                }
            } else {
                if(![0,10,11].includes(this.agendamento.telefone.replace(/\D/g, '').length)){
                    return this.notify('Verifique o número de telefone informado.', true)
                }
                if(this.agendamento.tipo === 'avulso'){
                    postConfigObj = this.buildPostAvulsoEdit()
                    this.submit(postConfigObj)
                }
                else if (this.agendamento.tipo === 'mensal' && this.agendamento.vuecalObj.info.isHorarioFixo == 0){
                    postConfigObj = this.buildPostAvulsoMensalEdit()
                    this.submit(postConfigObj)
                }
                else {
                    this.dialogEditOptions = true
                }
            }
        },
        desmarcar: function() {
            const reservaInfo = {
                nome: this.agendamento.nome,
                quadra: this.quadraSelecionadaNome,
                data: this.formatedDate,
                dia_semana: moment(this.selectedDate).format('dddd'),
                hora: this.formatedStarHour,
                duracao: this.durationStr,
                preco: this.agendamento.preco,
                obs: this.agendamento.observacao,
                tipo: this.agendamento.tipo,
                codigo_web: this.agendamento.vuecalObj.info.codigo_web,
            }

            if (this.agendamento.vuecalObj.info.pix_status == 'pendente' && moment(this.agendamento.vuecalObj.info.pix_expiration, 'DD-MM-YY HH:mm:ss').isAfter(moment.now())) {
                this.snackbar.whatsappMsg = this.getWhatsMsg('web_pix_pendente', reservaInfo)
                this.notify('Aguardando pagamento do pix até '+this.agendamento.vuecalObj.info.pix_expiration+'.', false, true, this.telefoneUnformatted, this.snackbar.whatsappMsg)
                return
            }
            let msg = 'Você tem certeza que deseja desmarcar este evento?'
            if (this.agendamento.vuecalObj.info.pix_status == 'pendente')
                msg = 'Aguardando pagamento do PIX até '+this.agendamento.vuecalObj.info.pix_expiration+'. ' + msg

            this.confirm(msg).then((confirm) => {
                if (!confirm) return this.dialog = false
                this.tipoMensagem = "liberado"
                let postConfigObj = {}


                if (this.agendamento.tipo === 'avulso' || this.agendamento.vuecalObj.info.isHorarioFixo == 0) {
                    this.snackbar.whatsappMsg = this.getWhatsMsg('avulso_desmarcado', reservaInfo)
                    postConfigObj = this.buildPostDesmarcarAvulso()
                    this.submit(postConfigObj, 1)
                    return this.dialog = false
                }

                if (this.agendamento.tipo === 'fixo' || this.agendamento.tipo === 'mensal') {
                    this.snackbar.whatsappMsg = this.getWhatsMsg('recorrente_desmarcado', reservaInfo)
                    postConfigObj = this.buildPosrDesmarcarFixoMensalista()
                    this.$http({
                        method: 'post',
                        url: '/apiweb/getAtivosFuturosReservaFixo',
                        data: {
                            data: this.selectedDate,
                            reservafixo_id: this.agendamento.vuecalObj.info.reservaFixo
                        }
                    }).then(response => {
                        let conflitos = response.data.conflitos
                        if (conflitos.length) {
                            conflitos = conflitos.map(d => moment(d,'YYYY-MM-DD').format('DD/MM/YY'))
                            let confirmMsg = 'Algumas datas no futuro foram editadas, como nome ou observação: ' +
                                        conflitos.join(', ') +
                                        '. Essas edições também serão excluídas. Deseja continuar?'

                            this.confirm(confirmMsg)
                                .then(confirm => {
                                    if(confirm === true){
                                        this.submit(postConfigObj, 1)
                                    }
                                })

                            this.load = false
                        } else {
                            this.submit(postConfigObj, 1)
                        }
                    }).catch(error => {
                        this.load = false
                        this.notify('Erro ao consultar os ativos futuros!', true)
                    })
                }
            })
        },
        desmarcarSemana: function() {
            this.confirm('Você tem certeza que deseja desmarcar a semana deste evento?').then((confirm) => {
                if (!confirm) return this.dialog = false
                this.tipoMensagem = "liberado essa semana"

                const reservaInfo = {
                    nome: this.agendamento.nome,
                    quadra: this.quadraSelecionadaNome,
                    data: this.formatedDate,
                    dia_semana: moment(this.selectedDate).format('dddd'),
                    hora: this.formatedStarHour,
                    duracao: this.durationStr,
                    preco: this.agendamento.preco,
                    obs: this.agendamento.observacao,
                    tipo: this.agendamento.tipo,
                    codigo_web: this.agendamento.vuecalObj.info.codigo_web,
                }
                this.snackbar.whatsappMsg = this.getWhatsMsg('recorrente_liberado', reservaInfo)

                let postConfigObj = {}
                postConfigObj = this.buildPostLiberarSemana()
                this.submit(postConfigObj)
                this.dialog = false
            })
        },
        submit: function (postConfigObj, cancelamento = 0) {
            if (this.agendamento.action == "add" && !this.horarioAdjacente) {
                return this.notify('Os horários precisam ser adjacentes!', true)
            }
            if(![0,10,11].includes(this.agendamento.telefone.replace(/\D/g, '').length)){
                return this.notify('Verifique o número de telefone informado.', true)
            }
            this.load = true
            if (postConfigObj.url) {
                this.$http({
                    method: 'post',
                    url: postConfigObj.url,
                    data: postConfigObj.postBody
                }).then(response => {
                    //dados = JSON.parse(response.data.agenda)
                    if(response.data.mensagem == "HorarioMarcado") {
                        this.load = false
                        return this.notify('Operação negada! Horário já possui marcação avulsa nesta data ou fixo no futuro.', true)
                    } else if (response.data.mensagem == "Permissao negada"){
                        return this.notify('Pemissão negada ou horário bloqueado!', true)
                    }
                    this.fetchHorarios()
                    this.dialog = false
                    this.dialogEditOptions = false
                    this.load = false
                    if (this.agendamento.action == "add")
                        this.notify('Horário '+this.agendamento.tipo+" "+this.tipoMensagem+' com sucesso!', false, true, this.telefoneUnformatted, this.whatsConfirmationText)
                    else if (cancelamento == 1)
                        this.notify('Horário Desmarcado com sucesso!', false, true, this.telefoneUnformatted, this.snackbar.whatsappMsg)
                    else if (this.tipoMensagem == 'liberado essa semana')
                        this.notify('Horário liberado essa semana com sucesso!', false, true, this.telefoneUnformatted, this.snackbar.whatsappMsg)
                }).catch(error => {
                    // if (error.response) {
                    //     // The request was made and the server responded with a status code
                    //     // that falls out of the range of 2xx
                    // } else if (error.request) {
                    //     // The request was made but no response was received
                    //     // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    //     // http.ClientRequest in node.js
                    // } else {
                    //     // Something happened in setting up the request that triggered an Error
                    // }
                    this.load = false
                    this.fetchHorarios()
                    this.notify('Horário indisponível, por favor tente outro disponível!', true)
                })
            }
        },
        orderHorarios: function (){
            let array = []
            for (let i = 0; i < this.agendamento.horariosMarcados.length; i++) {
                let obj = this.horariosLivres[this.agendamento.quadraMarcada].find(x => {
                    return x.id == this.agendamento.horariosMarcados[i]
                })
                array.push(obj)
            }
            array.sort((a, b) => ((moment(a.hora_inicio,'HH:mm').format('HH:mm')) > (moment(b.hora_inicio,'HH:mm').format('HH:mm'))) ? 1 : -1)
            return array
        },
        setNumberOfSplits: function () {
            // Configura quadras a exibir conforme o filtro
            let quadras = (this.quadrasDisplayedByFilter.length)
                ? this.quadras.filter(q => this.quadrasDisplayedByFilter.includes(q.id)).map(q => q.id)
                : this.quadras.map(q => q.id)

            // ignore carrosel if is mobile
            if ( detectMobileBrowser() ) {
                this.quadrasDisplayed = quadras
                this.carroselSplitDays.pages = []
                this.carroselSplitDays.pageLength = 0
                this.carroselSplitDays.numberOfPages = 0
                return
            }

            // Variáveis para controle do carrosel
            let vuecalContainerWidth = document.getElementById('vuecal').offsetWidth
            let pageLength = Math.trunc(
                vuecalContainerWidth / this.vuecalConfig.minCellWidth
            )
            let numberOfPages = Math.ceil(quadras.length / pageLength)

            let chunck = 0
            this.carroselSplitDays.pages = []
            while(chunck < quadras.length) {
                this.carroselSplitDays.pages.push(
                    quadras.slice(chunck, chunck + pageLength)
                )
                chunck += pageLength
            }

            this.carroselSplitDays.pageLength = pageLength
            this.carroselSplitDays.numberOfPages = numberOfPages

            if (quadras.length)
                this.quadrasDisplayed = this.carroselSplitDays.pages[this.carroselSplitDays.pageNow]
            else
                this.quadrasDisplayed = []
        },
        inicializeCarroselQuadras: function() {
            if (!document.querySelector('#vuecal .vuecal__flex.vuecal__split-days-headers'))
                return delay(this.inicializeCarroselQuadras, 200) // Recursão enquanto não estiver inicializado o vuecal

            let buttomLeft = document.getElementById('arrow-splitdays-left')
            buttomLeft.addEventListener('click', () => this.rollCarroselSplitdays('left'), false)
            document.querySelector('#vuecal .vuecal__flex.vuecal__split-days-headers').appendChild(buttomLeft)

            let buttomRight = document.getElementById('arrow-splitdays-right')
            buttomRight.addEventListener('click', () => this.rollCarroselSplitdays('right'), false)
            document.querySelector('#vuecal .vuecal__flex.vuecal__split-days-headers').appendChild(buttomRight)

            document
                .querySelector('#vuecal > .vuecal__header')
                .setAttribute('id', 'container-desktop-split')
            document
                .querySelector('#vuecal .vuecal__body > div')
                .setAttribute('id', 'container-mobile-split')
            document
                .querySelector('#vuecal .vuecal__split-days-headers')
                .setAttribute('id', 'split-days-headers')

            this.toogleLayoutMobile()

            window.addEventListener('resize', debounce( () => {
                if (this.oldSizeOfScreen !== window.innerWidth) { // Para ignorar eventos resizes duplicados
                    this.oldSizeOfScreen = window.innerWidth // Atualiza a referência de último tamanho

                    this.setNumberOfSplits()

                    this.toogleLayoutMobile()
                }
            }, 500))
        },
        setEventSplit: function() {
            this.events.forEach(ev => ev.split = 1 + this.quadrasDisplayed.findIndex((id, k) => id == ev.info.quadra_id))
            this.setIntervalConfigVuecal()
        },
        rollCarroselSplitdays: function(direction) {
            switch (direction) {
            case 'left':
                if (this.carroselSplitDays.pageNow > 0) this.carroselSplitDays.pageNow--
                else return
                break
            default:
                if (this.carroselSplitDays.pageNow < this.carroselSplitDays.numberOfPages - 1) this.carroselSplitDays.pageNow++
                else return
                break
            }

            this.quadrasDisplayed = this.carroselSplitDays.pages[this.carroselSplitDays.pageNow]
            // Atualiza split index nos eventos
            this.setEventSplit()
        },
        getLabelEvent: function(ev) { // somente usar fontawesome, material icons não
            let obs = '', icone = ''
            let pagamentoOnline = ''
            let pixIcon = ''
            if (ev.observacoes) obs = '<i aria-hidden="true" class="event-icon-type v-icon notranslate fa fa-info theme--light"></i>'
            if (ev.pagamento_online) pagamentoOnline = '<i aria-hidden="true" class="event-icon-type v-icon notranslate fas fa-dollar-sign theme--light"></i>'
            if (ev.pix_status){
                let pendente = 'pix-icon-pending'
                if(ev.pix_status == 'pago') pendente = ''
                pixIcon = `<i aria-hidden="true" class="event-icon-type v-icon notranslate fa-brands fa-pix ${pendente}"></i>`
            }
            switch (ev.tipo) {
            case 'mensal':
                icone = 'fa fa-calendar-alt'
                break
            case 'fixo':
                icone = 'fa fa-thumbtack'
                break
            case 'avulso':
                icone = 'fa fa-user'
                if (["web","pix"].includes(ev.tipo_marcacao)){
                    icone = 'fas fa-globe-americas '
                }
                break
            }
            let tipo = `<i aria-hidden="true" class="event-icon-type v-icon notranslate ${icone} theme--light"></i>`
            let start = moment(ev.start, 'YYYY-MM-DD HH:mm:ss')
            let end = moment(ev.end, 'YYYY-MM-DD HH:mm:ss')
            let duration = moment.duration(end.diff(start))//moment.duration(end.diff(start, 'hours'), 'hours')
            let minutes = duration.asMinutes()
            let margin2CenterEvent = 0
            let customClass = ''
            let style = `${obs}${pagamentoOnline}${pixIcon}${tipo}
                    </div>`
            if (minutes < 30) customClass = 'interval-15'
            else if (minutes == 30) customClass = 'interval-30'
            else if (margin2CenterEvent >= 0) {
                customClass = 'interval-normal'
                margin2CenterEvent = (minutes/2) - 20
                style = `${obs}${pagamentoOnline}${pixIcon}
                    </div>
                    <span style="position: absolute; left: calc(50% - 60px);">${tipo}</span>`
            }
            return {
                innerHTML: `<div style="margin-top: ${margin2CenterEvent}px" >
                        <span class="event-client-name d-inline-block text-truncate" style="max-width: 60%;">${ev.nome}</span>
                        ${style}`,
                customClass: customClass,
            }
        },
        changeQuadra: function(event) {
            let esportesQuadra = this.quadras.filter(q => this.agendamento.quadraMarcada == q.id)[0].esportes
            this.agendamento.horariosMarcados = []
            this.agendamento.esporte = esportesQuadra[0].id
        },
        toogleLayoutMobile: function() {
            this.isMobile = detectMobileBrowser() // Set mobile flag

            if (window.innerWidth < 1264) { // Small Screen
                // Move MiniCalendar
                document.getElementById('calendarMobileContainer').append(
                    document.getElementById('mini-calendar')
                )
            } else { // Desktop
                // Move MiniCalendar
                document.getElementById('calendarDesktopContainer').insertBefore(
                    document.getElementById('mini-calendar'),
                    document.getElementById('addEvent')
                )
            }

            if ( this.isMobile ) { // Mobile
                document.querySelector('#vuecal').classList.add('mobile')
                // Move Split Header
                document.querySelector('#container-mobile-split').insertBefore(
                    document.querySelector('#split-days-headers'),
                    document.querySelector('#container-mobile-split > .vuecal__bg')
                )
                // Add function Scrool header split days
                document.querySelector('#container-mobile-split .day-view')
                    .addEventListener('scroll', this.scroolHorizonSplitDays, false)
                this.scroolHorizonSplitDays() // Incialize scrooll
            } else { // Desktop
                document.querySelector('#vuecal').classList.remove('mobile')
                // Move Split Header
                document.querySelector('#container-desktop-split').appendChild(
                    document.querySelector('#split-days-headers')
                )
                // Remove function Scrool header split days
                document.querySelector('#container-mobile-split .day-view')
                    .removeEventListener('scroll', this.scroolHorizonSplitDays, false)
                document.getElementById('split-days-headers').style.left = null
            }

            // Custom min size column
            if (window.innerWidth < 450)
                this.vuecalConfig.minCellWidth =
                    document.querySelector('#container-mobile-split').offsetWidth
                    - document.querySelector('#vuecal .vuecal__time-column').offsetWidth
            else
                // Remove custom min size column
                this.vuecalConfig.minCellWidth = 250
        },
        getHorarioLivre: function(dateTime, quadraId) {
            let momento = moment(dateTime)
            let day = momento.date()
            let month = momento.month()
            let year = momento.year()
            for (const h of this.horariosLivres[quadraId]) {
                let inicio = moment(h.hora_inicio, 'HH:mm:ss').year(year).month(month).date(day)
                let fim = inicio.clone().add(h.duracao, 'minutes')
                if (momento.isBetween(inicio, fim, undefined, '[)'))
                    return h.id
            }
            return null
        },
        scroolHorizonSplitDays: function() {
            let element = document.querySelector('#container-mobile-split .day-view')
            // TODO: Verificar possibilidade de usar prefixo -webkit-
            document.getElementById('split-days-headers').style.left = `calc(3em - ${element.scrollLeft}px)`
        },
        bloquearHorarios: function(horariosBloq, label = 'Horário Bloqueado') {
            let className = 'blocked'
            this.quadras.map(q =>
                horariosBloq[q.id].forEach(h => {
                    if (label === 'Horário Bloqueado'){
                        className = moment(h.fim).diff(moment(h.inicio)) < 3600000 ? 'blocked hour-lock tiny' : 'blocked hour-lock'
                    } else className = ((moment(h.fim).diff(moment(h.inicio)) < 3600000) || (moment(h.inicio).format('hh:mm') === '23:30')) ? 'blocked tiny' : 'blocked'
                    this.events.push({
                        start: h.inicio,
                        end: h.fim,
                        title: label + `<span>&nbsp;</span>`,
                        class: className,
                        split: null,
                        info: { ...h, quadra_id: q.id },
                        background: true,
                        editable: false, // Propriedade não original do VueCal
                        deletable: false,
                        resizable: false,
                        draggable: false
                    })
                })
            )
        },
        applyFilter: function(event) {
            this.quadrasDisplayedByFilter = event
            this.setNumberOfSplits()
            this.setEventSplit()
        },
        setIntervalConfigVuecal: function() {
            if(!this.featuresFlags.calcRange) {
                this.vuecalConfig.startDay = 0 * 60
                this.vuecalConfig.endDay = 24 * 60
                return
            }

            // Inicialização das variáveis usadas para guardar o menor e maior horário
            let minIntervalHour = moment(this.selectedDate)
                .set({hour: 23, minute: 59, second: 59, millisecond: 999})
            , maxIntervalHour = moment(this.selectedDate)
                .set({hour: 0, minute: 0, second: 0, millisecond: 0})

            // Filtra somente os horários de fechamento das quadras exibidas
            let horariosLivres = Object.entries(this.horariosLivres)
                .filter(([key, h]) => this.quadrasDisplayed.includes(parseInt(key)))
                .map(([key, h]) => ([key, h.slice()]))

            // Filtra somente os horários de fechamento das quadras exibidas
            // Exclui eventos de bloqueio
            // Agrupa por quadra
            let eventos = Object.entries(groupBy(
                this.events
                    .filter(e => this.quadrasDisplayed.includes(e.info.quadra_id)
                        && e.class !== 'blocked')
                    .map(e => e.info),
                'quadra_id'))
                .map(([key, e]) => ([key, e.slice()]))

            // Busca menor horário livre
            for (const [q_id, q_hor] of horariosLivres) {
                let minStart = moment(this.selectedDate)
                    .set({hour: 23, minute: 59, second: 59, millisecond: 999})
                for (const h of q_hor) {
                    let inicio = moment(h.hora_inicio, 'HH:mm:ss')
                    if(inicio.isBefore(minStart))
                        minStart = inicio
                }
                if(minStart.isBefore(minIntervalHour))
                    minIntervalHour = minStart
            }

            // Busca maior horário livre. Lógica similar ao for de menor horário!
            for (const [q_id, q_hor] of horariosLivres) {
                let maxEnd = moment(this.selectedDate)
                    .set({hour: 0, minute: 0, second: 0, millisecond: 0})
                for (const h of q_hor) {
                    let fim = moment(h.hora_inicio, 'HH:mm:ss').add(h.duracao, 'm')
                    if(fim.isAfter(maxEnd))
                        maxEnd = fim
                }
                if(maxEnd.isAfter(maxIntervalHour))
                    maxIntervalHour = maxEnd
            }

            // Busca evento que ocorre mais cedo. Mesma lógica do for de menor horário!
            for (const [q_id, q_hor] of eventos) {
                let minStart = moment(this.selectedDate)
                    .set({hour: 23, minute: 59, second: 59, millisecond: 999})
                for (const e of q_hor) {
                    let inicio = moment(e.start, 'YYYY-MM-DD HH:mm:ss')
                    if(inicio.isBefore(minStart))
                        minStart = inicio
                }
                if(minStart.isBefore(minIntervalHour))
                    minIntervalHour = minStart
            }

            // Busca maior horário livre. Lógica similar ao for de menor horário!
            for (const [q_id, q_hor] of eventos) {
                let maxEnd = moment(this.selectedDate)
                    .set({hour: 0, minute: 0, second: 0, millisecond: 0})
                for (const e of q_hor) {
                    let fim = moment(e.end, 'YYYY-MM-DD HH:mm:ss')
                    if(fim.isAfter(maxEnd))
                        maxEnd = fim
                }
                if(maxEnd.isAfter(maxIntervalHour))
                    maxIntervalHour = maxEnd
            }

            this.vuecalConfig.startDay = minIntervalHour.hour() * 60
            this.vuecalConfig.endDay = (
                (maxIntervalHour.isAfter(moment(this.selectedDate)))
                    ? 24
                    : maxIntervalHour
            ) * 60
        },
        eventClickEventLocked: function(agendamentoVuecal) {
            let quadra = this.quadras.find(q => q.id === agendamentoVuecal.info.quadra_id)
            this.confirm(`Deseja remover TODOS os bloqueios de ${quadra.nome} neste dia?`)
                .then(confirm => {
                    if(confirm === true){
                        this.load = true
                        this.$http({
                            method: 'post',
                            url: '/apiweb/desbloqueiaHorarios',
                            data: {
                                quadra_id: quadra.id,
                                data: this.selectedDate,
                            }
                        }).then(async (response) => {
                            if (response.data.success === true) {
                                await this.fetchHorarios()
                                this.notify('Horários desbloqueados com sucesso!', false)
                            } else {
                                this.notify('Houve um erro! Tente novamente!', true)
                            }
                            this.load = false
                        })
                    }
                })
        },
    }
}
</script>

<style lang="sass" scoped>
    @import '@assets/css/global-vars.sass'


    *
        margin: 0
        color: #383737

        ::v-deep // Usado para ajustar bug por causa do uso de scoped neste style. É um alias para o Deep Selector >>>

            // Label split quadras
            #vuecal .day-split-header
                font-family: Roboto
                font-size: 1.3em
                padding: 0.35em 0
                // border-right: 1px solid $borderGray
            // Ajuste coluna de horarios
            #vuecal .vuecal__time-column
                margin-top: 0
            #vuecal.mobile
                > .vuecal__time-column
                    margin-top: 0
                #split-days-headers:before
                    content: ''
                    position: absolute
                    left: -3em
                    width: 3em
                    height: 115%
                    background: $flatGray
            // Container Label split
            #vuecal .vuecal__split-days-headers
                position: relative
                height: 35px
                min-height: 35px
                background: $flatGray
                border-top: 1px solid $borderGray
                border-left: 1px solid $borderGray
                border-right: 1px solid $borderGray

                > .day-split-header
                    display: inline-block
                    height: 34px
                    width: $minWidthSplit
                    min-width: $minWidthSplit
                    padding-left: 10px
                    padding-right: 10px
                    white-space: nowrap
                    overflow: hidden
                    text-overflow: ellipsis
                    text-align: center

                > .day-split-header
                    border-left: 1px solid $borderGray

                &:first-child
                    border-left: none

            #container-desktop-split .vuecal__split-days-headers
                padding-left: 40px


            // Buttom Carrosel Split days
            .arrow-splitdays
                position: absolute
                top: 0.2em
                z-index: 1
                font-size: 16.8px
                background: none
                border: none
                outline: none
            #arrow-splitdays-left
                left: 15px
            #arrow-splitdays-right
                right: 15px
            #button-change-day
                color: $white
                margin: 0 15px
                font-size: 1.4em
                text-shadow: 0px 0px 1px $flatBlack
            .vuecal__time-column
                border-right: 1px solid $borderGray
            .vuecal__cell:before
                border-left: none

            // Custom events
            .vuecal__cell-events
                font-family: Open Sans, Roboto
                font-size: 1em
            .vuecal__cell-events *
                color: $white
            .event-client-name
                margin-right: 10px
                vertical-align: sub
            .event-icon-type
                margin: 0 5px
                font-size: 0.9em

            // Several Adjusts
            #mini-calendar
                .vuecal__body,
                .vuecal__header > .vuecal__weekdays-headings
                    padding-left: 3px
                    padding-right: 2px
            .vuecal__now-line
                color: $orange
                border-top-width: 1.5px
                border-top-style: dashed
            .vuecal__now-line:before
                top: -9px
                border-width: 8px
            > *
                font-size: 1em
            .v-application--wrap
                min-height: inherit
            .vuecal
                margin: auto
            #vuecal
                max-height: 70vh
                max-height: -webkit-calc(100vh - 148px)
                max-height: -moz-calc(100vh - 148px)
                max-height: calc(100vh - 148px)
            #vuecal,
            #mini-calendar
                border-radius: 6px
                overflow: hidden
            .vuecal__time-column .vuecal__time-cell .line:before
                border-color: darken($lightGray, 5%)
            .vuecal__time-cell-label
                color: #000000
                font-size: 1.2em
            .vuecal__title > span
                font-weight: 600
            .modal-icon-close
                position: absolute
                top: 20px
                right: 20px
                font-size: 40px
            .v-dialog.modelForm > .v-card
                padding-top: 15px
            .v-card__actions button
                margin: 0 10px
            .v-card__actions button:first-child,
            .v-card__actions button:last-child
                margin: 0
            .money-prefix .v-text-field__slot
                position: relative
                &::before
                    content: 'R$'
                    position: absolute
                    top: 50%
                    left: 0
                    transform: translateY(-50%)
                input
                    padding-left: 30px
            .vuecal--rounded-theme.vuecal--blue-theme:not(.vuecal--day-view)
                .vuecal__cell--today .vuecal__cell-content
                    color: $white !important
                    background-color: $blue !important
                .vuecal__cell--selected .vuecal__cell-content
                    border: .5px solid $blue
                    color: $blue
                    font-weight: bold
                    box-shadow: 0px 0px 3px 1px $blue
                    // background-color: $white
                .vuecal__cell--disabled,
                .vuecal__cell
                    cursor: pointer
                .vuecal__cell--disabled .vuecal__cell-content
                    background-color: rgba($gray, .35)
                .vuecal__cell-content
                    background: rgba($blue, .25)
            .vuecal.pastDate .vuecal__title-bar
                background-color: $gray
            .vuecal.pastDate .vuecal__bg
                background: repeating-linear-gradient(-45deg, transparent, transparent 10px, #f2f2f2 0, #f2f2f2 20px)
            .vuecal--rounded-theme .vuecal__cell-content
                border-radius: 50%
            .modal-form header > .v-toolbar__content
                justify-content: space-between

            // Custom centralization events
            .vuecal__event.interval-15,
            .vuecal__event.interval-30
                display: flex
                flex-direction: row
                flex-wrap: nowrap
                justify-content: center
                align-items: center
                .vuecal__event-title
                    display: inline-block
                    max-width: 60%
                    flex-direction: row
                    flex-wrap: nowrap
                    justify-content: space-space-between
                    align-items: center
                    margin-right: 5px
                    .event-client-name
                        display: inline-block
                        max-width: 75%
                        margin-right: 2px
                        overflow-x: hidden
                        text-overflow: ellipsis
                        white-space: nowrap
                    i
                        vertical-align: 1px

            .vuecal__event.interval-normal
                .vuecal__event-title
                    .event-client-name
                        display: inline-block
                        max-width: 75%
                        margin-right: 2px
                        overflow-x: hidden
                        text-overflow: ellipsis

            .vuecal__event-title.interval-normal
                display: inline-block
                width: 100%

            // Custom color style
            .vuecal__cell-split:nth-child(2n)
                background-color: rgba(162, 236, 248, 0.3)
            .vuecal__title-bar
                color: $white
                background-color: $blue
                .vuecal__title
                    text-shadow: 0px 0px 1px $flatBlack !important
            .vuecal__event.blocked
                cursor: default
                background: repeating-linear-gradient(-45deg, transparent, transparent 10px, $lightGray 0, $lightGray 20px)
                &:hover
                    z-index: 0
                *
                    color: $darkGray
            .pix-icon-pending
                color: $orange
            .pix-icon-paid
                color: darkseagreen

            .vuecal__event.blocked.tiny
                display: inline-flex
                align-items: baseline
                justify-content: center


            /* Different color for different event types. */
            .vuecal__event.ev-color-avulso,
            .v-btn.ev-color-avulso
                background-color: $avulso !Important
            .vuecal__event.ev-color-fixo,
            .v-btn.ev-color-fixo
                background-color: $fixo !Important
            .vuecal__event.ev-color-mensal,
            .v-btn.ev-color-mensal
                background-color: $mensal !Important
            .vuecal__event
                cursor: pointer
                box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.9)

                &:not(.blocked)
                    width: 90% !important
                    width: calc(100% - 30px)
                    margin-left: 5%
                    margin-left: calc(15px)
                    border-radius: 10px
                &.blocked.hour-lock
                    cursor: pointer
                    &.vuecal__event--focus
                        z-index: 0
                    &:hover,
                    &.vuecal__event--focus:hover
                        background: repeating-linear-gradient(-45deg, $white, $white 10px, #E5E5E5 0, #E5E5E5 20px)
                        z-index: 3 !important

                &.blocked.hour-lock.tiny
                    display: inline-flex
                    align-items: center
                    justify-content: center

            .vuecal__labels
                font-size: 1.8em
                font-weight: bold
                margin: 4px 0
            .vuecal__event-title
                font-size: 1.2em
                font-weight: bold
                margin: 4px 0
            .vuecal__event-time
                display: inline-block
                vertical-align: middle
                font-weight: bold
            .vuecal__event-content
                font-style: italic

            .modal-form
                @media (max-width: 768px)
                    .v-input
                        .v-text-field__details,
                        .v-messages
                            min-height: 0px !important

                @media (max-width: 520px)
                    .v-card__actions

                        button.v-btn.clustered
                            margin: 10px 0 !important
                            padding: 0 10px
                            border-radius: 0
                            box-shadow: none

                            &:first-child
                                border-top-left-radius: 4px
                                border-bottom-left-radius: 4px

                            &:last-child
                                border-top-right-radius: 4px
                                border-bottom-right-radius: 4px

                        button.v-btn
                            margin: 10px 5px !important
                            flex-grow: 1

                        > .d-flex > div
                            display: flex
                            width: 100%
                            min-width: 100%
                            flex-wrap: wrap

                @media (max-width: 380px)
                    > .v-card > .v-card__text
                        padding: 0 10px

            @media (max-width: 500px)
                #vuecal
                    max-height: 84vh
                    max-height: -webkit-calc(100vh - 110px)
                    max-height: -moz-calc(100vh - 110px)
                    max-height: calc(100vh - 110px)

            @media (max-width: 360px)
                #vuecal
                    max-height: 85vh
                    max-height: -webkit-calc(100vh - 92px)
                    max-height: -moz-calc(100vh - 92px)
                    max-height: calc(100vh - 92px)
</style>